import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class EncryptionService {
    private gl_flk = '14f78l9'
    private  cr_chw = '3j2o5p7xy'
    
    private  and_mnd = 'abx3y8n'
    private sg_rat = 'tlop0cdxy'
    
    encryptData(data: any): string {
      const jsonData = JSON.stringify(data);
    
      // Convert key and IV to WordArray using parse
      const key = CryptoJS.enc.Utf8.parse(this.gl_flk + this.cr_chw);
      const IV = CryptoJS.enc.Utf8.parse(this.and_mnd + this.sg_rat);
    
      const encryptedlogin = CryptoJS.AES.encrypt(
        jsonData,
        key,
        {
          keySize: 128 / 8,
          iv: IV,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
        }
      ).toString();
    
      return encryptedlogin;
    }
    
}