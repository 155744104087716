import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MyInterceptor } from './core/services/http.interceptor';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxUiLoaderConfig, NgxUiLoaderModule } from 'ngx-ui-loader';
import { NgSelectModule } from '@ng-select/ng-select'; // Import NgSelectModule
import { QuillModule } from 'ngx-quill'
import { NgApexchartsModule } from "ng-apexcharts";
import { JwtModule } from '@auth0/angular-jwt';
import { AuthenticationService } from './core/services/auth.service';
import { HighchartsChartModule } from 'highcharts-angular';
import { RequestInterceptor } from './core/services/request.interceptor';
import { ResponseInterceptor } from './core/services/response.interceptor';
import { HeaderInterceptor } from './core/services/header.interceptor';

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  "fgsSize": 30,
  "pbColor": "#192B6E",
  "fgsColor": "#192B6E",
  "overlayColor": "rgba(0, 0, 0, 0.1)",
  "hasProgressBar": false,
};


export const jwtOptionsFactory = (dependency:any) => ({
  tokenGetter: () => dependency.getToken(),
  whitelistedDomains: []
});
export function tokenGetter() {
  return sessionStorage.getItem("token");
}
@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    JwtModule.forRoot({config: {
      tokenGetter: tokenGetter,
      allowedDomains: ["https://dev.teksun.com/"],
      },
    }),
    BrowserAnimationsModule,
    NgSelectModule,
    NgMultiSelectDropDownModule,
    ToastrModule.forRoot(),
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    QuillModule.forRoot(),
    NgApexchartsModule,
    HighchartsChartModule
    ],
  providers: [AuthenticationService,{
    provide: HTTP_INTERCEPTORS,
    useClass: MyInterceptor,
    multi: true
  },
  // {
  //   provide: HTTP_INTERCEPTORS,
  //   useClass: InternetInterceptor,
  //   multi: true,
  // },
  // {
  //   provide: HTTP_INTERCEPTORS,
  //   useClass: ErrorhandlerInterceptor,
  //   multi: true,
  // },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: HeaderInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: RequestInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ResponseInterceptor,
    multi: true,
  },],
  bootstrap: [AppComponent]
})
export class AppModule { }
