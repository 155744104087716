import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
// To remove console from build
if (environment.production) {
  enableProdMode();
  if (window) {
    window.console.log = function () {};
    window.console.warn = function () {};
    window.console.error = function () {};
  }
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
