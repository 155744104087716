import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Message } from '../helpers/message';
import { Dat } from '../helpers/dat';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Router } from '@angular/router';
@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
  constructor(private ngxService: NgxUiLoaderService, private router: Router) { }

  intercept(
    originalRequest: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.ngxService.start();

    if (Dat.get() != '' && Dat.get().token != '') {
      // check if the request has formdata
      if (originalRequest.body instanceof FormData) {
        const jwtTok = Dat.get().token;
        const requestWithAuthToken = originalRequest.clone({
          setHeaders: {
            authorization: `${jwtTok}`,
            'x-access-key': 'adbohkjfbdshjbsdjffkbj',
          },
        });

        this.ngxService.stop();
        return next.handle(requestWithAuthToken);
      }

      const jwtTok = Dat.get().token;
      const requestWithAuthToken = originalRequest.clone({
        setHeaders: {
          authorization: `${jwtTok}`,
          'x-access-key': 'adbohkjfbdshjbsdjffkbj',
        },
        body: this.sendMsg(originalRequest.body),
      });
      this.ngxService.stop();
      return next.handle(requestWithAuthToken);
    }
    else {
      this.ngxService.stop();
      return next.handle(originalRequest);
    }
  }

  private sendMsg(msg: {}) {
    const body: {} = msg ;
    return body;
  }
}