import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Message } from '../helpers/message';
import { Dat } from '../helpers/dat';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { map } from 'rxjs/operators';

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {
  constructor(private ngxService: NgxUiLoaderService) {}

  intercept(
    originalRequest: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.ngxService.start();

    return next.handle(originalRequest).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          event = event.clone({
            body: this.getMsg(event.body, event.body.data || ''),
          });
        }
        return event;
      })
    );
  }

  private getMsg(msg: {}, data: any) {
    const body: {} = { ...msg, data: JSON.stringify(data) === '{}' || data.length == 0 || '' ? [] : data };
    // const body: {} = { ...msg, data: JSON.stringify(data) === '{}' || data.length == 0 || '' ? {} : data };
    return body;
  }
}