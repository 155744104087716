import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpInterceptor, HttpEvent, HttpResponse, HttpRequest, HttpHandler } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import * as CryptoJS from 'crypto-js';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { EncryptionService } from './encryption.service';
@Injectable()

export class MyInterceptor implements HttpInterceptor {

  public message: any;
  public nonce: any = '35A90B73C39D97';
  public path: any;
  public privateKey: any = "4AF8AC8E124B6484BDDC5A9EB3D49";
  public secretKey: any = "PXGuKjNtjzLiyLRE0GzykvGcaZO5uWE0";
  public timestamp: any = 1615971782;

  constructor(
    private ngxLoader: NgxUiLoaderService,
    private encryptionService: EncryptionService,
    private router: Router
  ) { }

  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //Generate Nonce
    const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz';
    this.nonce = '';
    for (let i = 0; i < 8; i++) {
      const rnum = Math.floor(Math.random() * chars.length);
      this.nonce += chars.substring(rnum, rnum + 1);
    }
    //Generate TimeStamp
    this.timestamp = Math.floor(new Date().getTime() / 1000);
    //Generate TOken
    var generateHmacToken = CryptoJS.HmacSHA256(this.secretKey + this.timestamp + this.nonce, this.privateKey).toString(CryptoJS.enc.Hex);
    const token = sessionStorage.getItem("token");

    if (token) {
      const authReq = httpRequest.clone({
        headers: httpRequest.headers.set('nonce', this.nonce.toString()).set('timestamp', this.timestamp.toString()).set('token', generateHmacToken.toString()).set('authorization', 'Bearer ' + token)
      });

      httpRequest = authReq;
      this.ngxLoader.start();
    }
    else {
      const authReq = httpRequest.clone({
        headers: httpRequest.headers.set('nonce', this.nonce.toString()).set('timestamp', this.timestamp.toString()).set('token', generateHmacToken.toString())
      });
      httpRequest = authReq;
      this.ngxLoader.start();
    }

    return next.handle(httpRequest).pipe(
      tap(
        event => this.handleResponse(httpRequest, event),
        error => this.handleError(httpRequest, error)
      )
    );
  }

  handleResponse(httpRequest: HttpRequest<any>, event: any) {
    if (event instanceof HttpResponse) {
      this.ngxLoader.stop();
    }
  }

  handleError(httpRequest: HttpRequest<any>, error: any) {
    this.ngxLoader.stop();
    if (error.status == 400 || error.status == 401 || error.status == 404 || error.status == 500) {
      sessionStorage.clear();
      setTimeout(() => {
        this.router.navigate(['/login']);
      }, 1000);
    }
  }
}
