import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Message } from '../helpers/message';
import { Dat } from '../helpers/dat';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  constructor(private ngxService: NgxUiLoaderService) {}

  intercept(
    originalRequest: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.ngxService.start();

    if (Dat.get() == '') {
      // check if the request has formdata
      if (originalRequest.body instanceof FormData) {
        return next.handle(originalRequest);
      }
      const requestWithAuthToken = originalRequest.clone({
        setHeaders: {
          'x-verify-token': Dat.get().verifyToken || '',
        },
        body: this.sendMsg(originalRequest.body),
      });
      this.ngxService.stop();
      return next.handle(requestWithAuthToken);
    } else if (Dat.get() != '' && Dat.get().verifyToken) {
      if (originalRequest.body instanceof FormData) {
        const requestWithAuthToken = originalRequest.clone({
          setHeaders: {
            'x-verify-token': Dat.get().verifyToken,
          },
        });
        this.ngxService.stop();
        return next.handle(requestWithAuthToken);
      }

      const requestWithAuthToken = originalRequest.clone({
        setHeaders: {
          'x-verify-token': Dat.get().verifyToken,
        },
        body: this.sendMsg(originalRequest.body),
        
      });

      this.ngxService.stop();
      return next.handle(requestWithAuthToken);
    } else {
      this.ngxService.stop();
      return next.handle(originalRequest);
    }
  }

  private sendMsg(msg: {}) {
    // const body: {} = { data: Message.encryptData(msg), type: 1 };
    const body: {} =  msg ;

    return body;
  }
}